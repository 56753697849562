import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { MbmProvider } from '@amzn/react-arb-tools';
import React, { ReactNode } from 'react';
import { DEFAULT_LOCALE_EN_US, TRANSLATION_ROUTE } from 'src/constants/UIComponentsConstants';
import { AppContextProvider } from 'src/context/AppContextProvider';
import { AuthContextProvider } from 'src/context/AuthContextProvider';
import arbManifest from '../i18n/translations/arbManifest';
import AppRouter from './Navigation/AppRouter';

const localizationContext = new LocalizationContextBuilder().withLocale(DEFAULT_LOCALE_EN_US).withDefaultLocale(DEFAULT_LOCALE_EN_US).build();

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: localizationContext,
  urlPrefix: TRANSLATION_ROUTE
};

interface AppProps {
  children?: ReactNode; // Use ReactNode for children that could be multiple elements, text, numbers, or null
}

export const App: React.FC<AppProps> = ({ children }) => {
  return (
    <MbmProvider {...mbmOptions}>
      <AuthContextProvider>
        <AppContextProvider>
          <AppRouter />
        </AppContextProvider>
      </AuthContextProvider>
    </MbmProvider>
  );
};
