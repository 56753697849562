export const TRANSLATIONS_DIR_NAMESPACE = 'translations';

// This route is determined by translationOutputDir in configUtils
export const TRANSLATION_ROUTE = `/${TRANSLATIONS_DIR_NAMESPACE}`;

export const DEFAULT_LOCALE_EN_US = 'en-US';

export const APPLICATION_TITLE = 'FAST Input Tool';
export const APPLICATION_DESCRIPTION = `A new web based Input Tool to replace Excel Add-In.`;
export const PHONE_TOOL_LINK = 'https://phonetool.amazon.com/users/';

export enum LDAP_GROUPS {
  AD3_TEAM = 'ad3-team',
  FAST_ADMIN_USERS = 'fast-admin-users'
}
