import React, { useState, useEffect, useContext, createContext } from 'react';
import { AppContext, LoadingStatus } from 'src/models/ApplicationModels';
import { LoadingSpinner } from 'src/components/GenericComponents/Spinner';
import { Alert, Button, SpaceBetween } from '@amzn/awsui-components-react';

const AppContextInitialData: AppContext = {
  contextLoadingError: null,
  contextLoadingStatus: LoadingStatus.NotInitiated
};

const AppContextDetails = createContext(AppContextInitialData);
export const useAppContext = () => {
  return useContext(AppContextDetails);
};

export const AppContextProvider = ({ children }: any) => {
  const [visible, setVisible] = React.useState(true);
  const [contextLoadingError, setContextLoadingError] = useState<string | null>(null);
  const [contextLoadingStatus, setContextLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NotInitiated);

  useEffect(() => {
    setContextLoadingStatus(LoadingStatus.Loading);
    Promise.all([])
      .then(async (values) => {
        setContextLoadingStatus(LoadingStatus.Completed);
      })
      .catch((err) => {
        if (err !== 'No current user') {
          const errorMessage = 'Error while loading context. Some of the features in Application might not work as expected.';
          setContextLoadingError(errorMessage);
          setContextLoadingStatus(LoadingStatus.Failed);
        }
      });
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <>
      {contextLoadingStatus === LoadingStatus.Loading && <LoadingSpinner />}

      {contextLoadingStatus === LoadingStatus.Completed && (
        <AppContextDetails.Provider
          value={{
            contextLoadingStatus,
            contextLoadingError
          }}
        >
          {children}
        </AppContextDetails.Provider>
      )}

      {contextLoadingStatus === LoadingStatus.Failed && (
        <SpaceBetween size="m">
          <Alert
            onDismiss={() => setVisible(false)}
            dismissible={false}
            visible={visible}
            type="error"
            dismissAriaLabel="Close alert"
            header="Known issues/limitations"
            action={<Button onClick={reloadPage}>Retry</Button>}
          >
            Unable to load Application config. Please retry or check again later.
          </Alert>
        </SpaceBetween>
      )}
    </>
  );
};
