var endpointMap = {
	und: {
		"i18n.pages.homepage": "translation.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
