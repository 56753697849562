export const ENV_VARIABLES = Object.freeze({
  env: {
    Region: 'us-east-1',
    Stage: 'prod',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'd3nybwqteox7ww.cloudfront.net',
    CognitoUserPoolId: 'us-east-1_T02GL0OfV',
    CognitoIdentityPoolId: 'us-east-1:5fb264ce-1e05-4bba-8e67-64b7838d5082',
    AuthenticationType: 'AMAZON_COGNITO_USER_POOLS'
  }
});

export const getAmplifyAuthConfig = () => {
  return {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_T02GL0OfV',
      userPoolWebClientId: '6sff764nr8ujc6kgjc78fhk601',
      identityPoolId: 'us-east-1:5fb264ce-1e05-4bba-8e67-64b7838d5082',
      awsAccountId: '849042503220',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'fast-input-tool-prod.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: 'https://fast-input-tool.device.finance.amazon.dev',
        redirectSignOut: 'https://fast-input-tool.device.finance.amazon.dev',
        responseType: 'code'
      }
    }
  };
};
