import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ApplicationNavBar } from '../ApplicationNavBar';
import { PageNotFound } from '../GenericComponents/PageNotFound';
import { LandingPage } from './LandingPage';

class AppRouter extends Component {
  render() {
    return (
      <BrowserRouter>
        <ApplicationNavBar />

        <Routes>
          <Route path="/" element={<LandingPage />} />

          {/* Redirect all 404's to PageNotFound */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default AppRouter;
