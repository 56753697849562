import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from 'src/components/GenericComponents/Spinner';
import { LDAP_GROUPS } from 'src/constants/UIComponentsConstants';
import { LoadingStatus, UserAuthContext } from 'src/models/ApplicationModels';
import { configureTheLogger, logger } from '../utils/Logger';
import { getAmplifyAuthConfig } from 'src/services/AmplifyServices';

const initialData: UserAuthContext = {} as UserAuthContext;
const AuthContextDetails = createContext(initialData);
export const useAuth = () => useContext(AuthContextDetails);

// Provider component that wraps your app and makes auth object
export const AuthContextProvider = ({ children }: any) => {
  const userAuthData: UserAuthContext = useAuthProvider();
  if (userAuthData?.userAuthDataLoadingStatus === LoadingStatus.Loading) {
    return <LoadingSpinner />;
  } else {
    configureTheLogger(userAuthData.Alias, userAuthData);
    logger.info('User authenticated successfully!');
    return <AuthContextDetails.Provider value={userAuthData}>{children}</AuthContextDetails.Provider>;
  }
};

export const useAuthProvider = () => {
  const [userSession, setUserSession] = useState<any>();
  const [userCognitoAuthData, setUserCognitoAuthData] = useState<UserAuthContext>({
    ...initialData,
    userAuthDataLoadingStatus: LoadingStatus.Loading
  });

  useEffect(() => {
    Auth.configure(getAmplifyAuthConfig());

    const signInWithAmazonFederate = async () => {
      try {
        const userSessionDetails = await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
        setUserSession(userSessionDetails);
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      } catch (error: any) {
        console.error('Unable to sign in with AmazonFederate', error);
        setUserCognitoAuthData({
          ...userCognitoAuthData,
          userAuthDataLoadingStatus: LoadingStatus.Loading
        });
      }
    };

    Auth.currentAuthenticatedUser()
      .then(async (userSessionDetails) => {
        setUserSession(userSessionDetails);
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      })
      .catch(() => {
        signInWithAmazonFederate();
      });
  }, []);

  const getSessionDetails = (credentials: CognitoUserSession) => {
    // Extract the dateCreated timestamp
    const dateCreatedTimestamp = credentials.getIdToken().payload['identities'][0].dateCreated;
    const dateCreated = new Date(parseInt(dateCreatedTimestamp));
    // Determine if the user is new
    const isNewUser = isUserNew(dateCreated);

    const sessionDetails: UserAuthContext = {
      Alias: credentials.getIdToken().payload['identities'][0].userId,
      DisplayName: credentials.getIdToken().payload['custom:DISPLAY_NAME'],
      Email: credentials.getIdToken().payload['custom:EMAIL'],
      UserLDAPs: credentials.getIdToken().payload['custom:LDAP_GROUPS'],
      isAd3TeamMember: credentials.getIdToken().payload['custom:LDAP_GROUPS'].includes(LDAP_GROUPS.AD3_TEAM),
      isFastAdminLdapMember: credentials.getIdToken().payload['custom:LDAP_GROUPS'].includes(LDAP_GROUPS.FAST_ADMIN_USERS),
      isNewUser: isNewUser,
      error: '',
      userAuthDataLoadingStatus: LoadingStatus.Completed
    };
    return sessionDetails;
  };

  const NEW_USER_THRESHOLD_DAYS = 1;

  // Helper function to determine if the user is new
  const isUserNew = (dateCreated: Date) => {
    const now = new Date();
    const timeDiff = now.getTime() - dateCreated.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24); // Converting time difference to days
    return daysDiff <= NEW_USER_THRESHOLD_DAYS;
  };

  return userCognitoAuthData;
};
