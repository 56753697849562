import { AppLayout, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import WorkInProgress from '../GenericComponents/WorkInProgress';

export const LandingPage: React.FC = () => {
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  return (
    <AppLayout
      headerSelector="#h"
      disableContentPaddings={true}
      notifications={<Flashbar items={flashbarItems} />}
      navigationHide
      toolsHide
      contentType="dashboard"
      content={<WorkInProgress />}
    />
  );
};
