// This is used for Loading Status
export enum LoadingStatus {
  NotInitiated = 'Not Initiated',
  Loading = 'Loading',
  Completed = 'Completed',
  Failed = 'Failed'
}

export interface UserAuthContext {
  Alias: string;
  DisplayName: string;
  Email: string;
  UserLDAPs: string[];
  isAd3TeamMember: boolean;
  isFastAdminLdapMember: boolean;
  isNewUser: boolean;
  error: string;
  userAuthDataLoadingStatus: LoadingStatus;
}

export interface AppContext {
  contextLoadingError: string | null;
  contextLoadingStatus: LoadingStatus;
}