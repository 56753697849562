import * as React from 'react';
import { TopNavigation, TopNavigationProps } from '@amzn/awsui-components-react';
import { useAuth } from 'src/context/AuthContextProvider';
import { APPLICATION_TITLE, PHONE_TOOL_LINK } from 'src/constants/UIComponentsConstants';
import { useNavigate } from 'react-router-dom';

export const ApplicationNavBar = () => {
  const navigate = useNavigate();
  const userContext = useAuth();

  const appTitleClicked = (e: any) => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <div id="h">
      <TopNavigation
        className="app-primary-top-navigation"
        identity={{
          href: '#',
          title: APPLICATION_TITLE,
          onFollow: appTitleClicked
        }}
        utilities={[
          {
            type: 'menu-dropdown',
            text: userContext?.DisplayName,
            iconName: 'user-profile',
            items: [
              {
                id: 'user-phone-tool',
                text: userContext?.Email,
                href: PHONE_TOOL_LINK + userContext?.Alias,
                external: true,
                externalIconAriaLabel: ' (opens in new tab)'
              }
            ]
          }
        ]}
        i18nStrings={{
          searchIconAriaLabel: 'Search',
          searchDismissIconAriaLabel: 'Close search',
          overflowMenuTriggerText: 'More',
          overflowMenuTitleText: 'All',
          overflowMenuBackIconAriaLabel: 'Back',
          overflowMenuDismissIconAriaLabel: 'Close menu'
        }}
      />
    </div>
  );
};
